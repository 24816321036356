// src/pages/AdminLogin.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import adminService from '../services/adminService';

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await adminService.adminLogin(username, password);
            const sessionUUID = response.sessionUUID; // Get the UUID
            navigate(`/peteboonmang196/dashboard/${sessionUUID}`); // Use UUID only in the URL
        } catch (err) {
            setError('Invalid username or password');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
            <h1 className="text-3xl mb-8">Admin Login</h1>
            <form onSubmit={handleLogin} className="w-full max-w-sm">
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="w-full p-3 mb-4 bg-gray-800 border border-gray-700 rounded"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-3 mb-4 bg-gray-800 border border-gray-700 rounded"
                />
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <button type="submit" className="w-full p-3 bg-blue-500 rounded hover:bg-blue-600">
                    Login
                </button>
            </form>
        </div>
    );
};

export default AdminLogin;
