// src/components/BuyerRights.jsx
import React from 'react';
import SectionHeader from './common/SectionHeader';
import { FaCheck } from 'react-icons/fa';
import { createCheckoutSession } from '../services/checkoutService'; // Import the service

const BuyerRights = () => {
    const handleBuyNow = async () => {
        try {
            const checkoutUrl = await createCheckoutSession();
            window.location.href = checkoutUrl;
        } catch (error) {
            alert('Failed to initiate checkout. Please try again.');
        }
    };

    const rights = [
        'Complete Bundle',
        'Instant access',
        'Lifetime access',
        'Commercial use',
        'One-time payment',
        'Written rights to sell this bundle'
    ];

    return (
        <section className="py-16 bg-gray-900 text-white">
            <div className="container mx-auto px-4 text-center">
                {/* Update the SectionHeader color to white */}
                <SectionHeader title="What You Get with This Bundle" customClass="text-white" />
                <div className="mt-8 bg-gray-800 p-6 md:p-10 lg:p-12 rounded-lg shadow-md max-w-md md:max-w-lg lg:max-w-2xl mx-auto">
                    <h2 className="text-4xl lg:text-5xl font-bold text-yellow-400 mb-4">
                        $39 <span className="line-through text-gray-400">$159</span>
                    </h2>
                    {/* Make the Buy Now button longer */}
                    <button
                        onClick={handleBuyNow}
                        className="w-full lg:w-1/1 px-8 py-3 lg:py-4 bg-red-600 text-lg lg:text-2xl font-semibold rounded-md hover:bg-red-700"
                    >
                        Buy Now
                    </button>
                    <ul className="mt-6 lg:mt-8 space-y-4 text-left text-base lg:text-lg">
                        {rights.map((right, index) => (
                            <li key={index} className="flex items-center space-x-2">
                                <FaCheck className="text-blue-500 lg:text-xl" />
                                <span>{right}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default BuyerRights;
