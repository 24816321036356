import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

const ContactPage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-900"> {/* Ensures the background color covers entire page */}
            <Header />
            <main className="flex-grow">
                <ContactForm />
            </main>
            <Footer />
        </div>
    );
};

export default ContactPage;
