// src/components/adminComponents/Sidebar.jsx
import React from 'react';

const Sidebar = ({ currentSection, setCurrentSection }) => {
    return (
        <div className="bg-gray-800 text-white w-64 p-4 h-full">
            <h2 className="text-2xl mb-4">Admin Panel</h2>
            <nav>
                <ul>
                    <li
                        className={`p-2 cursor-pointer ${currentSection === 'purchases' ? 'bg-gray-700' : ''}`}
                        onClick={() => setCurrentSection('purchases')}
                    >
                        Purchases
                    </li>
                    {/* Add more sections here for future features */}
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
