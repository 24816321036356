import React from 'react';
import SectionHeader from './common/SectionHeader';
import { FaClock, FaDownload, FaMoneyCheckAlt } from 'react-icons/fa';

const BuyerBenefits = () => {
    const benefits = [
        {
            icon: <FaClock />,
            title: 'INSTANT ACCESS',
            description: 'After purchase we will send the download link in your email.'
        },
        {
            icon: <FaDownload />,
            title: 'LIFETIME ACCESS',
            description: 'Download every template to your computer. All templates are yours.'
        },
        {
            icon: <FaMoneyCheckAlt />,
            title: 'ONE-TIME PAYMENT',
            description: 'Yes, It\'s a One-time Fee. For every package you choose! No Monthly Payments!'
        }
    ];

    return (
        <section className="py-16 bg-white text-gray-900">
            <div className="container mx-auto px-4 text-center">
                <SectionHeader title="What You'll Benefit" />
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
                    {benefits.map((benefit, index) => (
                        <div key={index} className="bg-white shadow-md p-6 rounded-lg flex flex-col items-center">
                            <div className="text-5xl text-blue-500 mb-4">
                                {benefit.icon}
                            </div>
                            <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                            <p className="text-gray-600">{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BuyerBenefits;
