// src/components/common/SectionHeader.jsx
import React from 'react';

const SectionHeader = ({ title, customClass = 'text-gray-800' }) => {
    return (
        <div className="text-center my-8">
            {/* Apply the customClass here to dynamically change the text color */}
            <h2 className={`text-3xl font-semibold ${customClass}`}>{title}</h2>
            <div className="mt-2 w-20 h-1 bg-blue-600 mx-auto"></div>
        </div>
    );
};

export default SectionHeader;
