// src/components/adminComponents/LogoutButton.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import adminService from '../../services/adminService';

const LogoutButton = () => {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await adminService.adminLogout();
            navigate('/peteboonmang196'); // Redirect to login page after logout
        } catch (err) {
            console.error('Failed to log out:', err);
            setError('Logout failed. Please try again.');
        }
    };

    return (
        <div>
            <button
                onClick={handleLogout}
                className="p-2 bg-red-500 rounded hover:bg-red-600"
            >
                Logout
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    );
};

export default LogoutButton;
