// src/components/TermsOfServiceContent.jsx
import React from 'react';

const TermsOfServiceContent = () => {
    return (
        <section className="container mx-auto px-4 py-12 text-white">
            <h1 className="text-3xl font-bold mb-6 text-center">Terms of Service for Bundle Collections</h1>
            <div className="space-y-4">
                <h2 className="text-xl font-semibold mt-6">Introduction</h2>
                <p>
                    Welcome to Bundle Collections. These Terms of Service ("Terms") govern your use of our website and the purchase of our bundle of graphic elements. By accessing or using our services, you agree to comply with and be bound by these Terms. Please read them carefully.
                </p>
                <h2 className="text-xl font-semibold mt-6">Acceptance of Terms</h2>
                <p>
                    By using our website or purchasing our products, you confirm that you have read, understood, and agree to be bound by these Terms, as well as our Privacy Policy. If you do not agree to these Terms, you may not access or use our services.
                </p>
                <h2 className="text-xl font-semibold mt-6">Purchases and Payments</h2>
                <p>
                    All purchases made on Bundle Collections are subject to our acceptance. We reserve the right to refuse or cancel any order at our sole discretion. Payment must be made in full at the time of purchase. You agree to provide valid payment information and authorize us to charge the payment method provided.
                </p>
                <h2 className="text-xl font-semibold mt-6">License and Use of Products</h2>
                <p>
                    Upon purchase, you are granted a non-exclusive, non-transferable license to use the graphic elements for personal or commercial purposes, subject to any restrictions specified during the purchase. You may not resell, redistribute, or modify the graphic elements for resale.
                </p>
                <h2 className="text-xl font-semibold mt-6">Prohibited Conduct</h2>
                <p>
                    You agree not to engage in any of the following prohibited activities:
                </p>
                <ul className="list-disc list-inside ml-4">
                    <li>Using the website or products for unlawful purposes</li>
                    <li>Violating any applicable laws or regulations</li>
                    <li>Infringing on the intellectual property rights of Bundle Collections or third parties</li>
                    <li>Interfering with or disrupting the operation of our website or services</li>
                </ul>
                <h2 className="text-xl font-semibold mt-6">Intellectual Property Rights</h2>
                <p>
                    All content, products, and materials available on our website, including but not limited to text, graphics, logos, and software, are the property of Bundle Collections or our licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use our content without our prior written permission.
                </p>
                <h2 className="text-xl font-semibold mt-6">Termination</h2>
                <p>
                    We reserve the right to terminate or suspend your access to our services at any time, without notice, for conduct that we deem to be a violation of these Terms or for any other reason, at our sole discretion.
                </p>
                <h2 className="text-xl font-semibold mt-6">Limitation of Liability</h2>
                <p>
                    To the maximum extent permitted by law, Bundle Collections shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website or products. Your sole remedy for dissatisfaction with our services is to stop using them.
                </p>
                <h2 className="text-xl font-semibold mt-6">Indemnification</h2>
                <p>
                    You agree to indemnify and hold Bundle Collections, its affiliates, and employees harmless from any claims, liabilities, damages, or expenses arising out of your use of our services or violation of these Terms.
                </p>
                <h2 className="text-xl font-semibold mt-6">Changes to These Terms</h2>
                <p>
                    We may update these Terms from time to time to reflect changes in our practices or for legal reasons. Any changes will be posted on this page, and your continued use of our services constitutes acceptance of the updated Terms.
                </p>
                <h2 className="text-xl font-semibold mt-6">Governing Law</h2>
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
                </p>
                <h2 className="text-xl font-semibold mt-6">Contact Us</h2>
                <p>
                    If you have any questions or concerns about these Terms, please contact us at:
                </p>
                <p>
                    Email: <a href="mailto:support@bundlecollections.com" className="text-blue-400">support@bundlecollections.com</a>
                </p>
                <p>
                    Thank you for using Bundle Collections. We appreciate your business and strive to provide you with the best service possible.
                </p>
            </div>
        </section>
    );
};

export default TermsOfServiceContent;
