import React, { useEffect, useRef } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/sitelogo.png'; // Adjust the path based on your file structure

const Header = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Close the menu when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false); // Close menu if clicking outside
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <header className="bg-gray-900 text-white lg:px-32 lg:py-2 px-2 py-2 flex justify-between items-center w-full">
            {/* Logo wrapped with Link for navigation to Home */}
            <div className="flex-shrink-0">
                <Link to="/" onClick={() => setIsOpen(false)}>
                    <img src={logo} alt="Site Logo" className="h-14 lg:h-24" /> {/* Adjust the height as necessary */}
                </Link>
            </div>

            <div className="md:hidden" onClick={toggleMenu}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </div>

            {/* Navigation Menu - Slide in from the right, covering 1/3 of the screen */}
            <nav ref={menuRef} className={`fixed top-0 right-0 w-1/3 h-screen bg-gray-900 md:bg-transparent md:relative md:flex md:h-auto md:w-auto md:items-center transition-transform transform ${isOpen ? 'translate-x-0' : 'translate-x-full md:translate-x-0'} md:translate-none z-50 md:z-auto`}>
                <ul className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-10 text-lg md:text-base justify-center items-center h-screen md:h-auto">
                    {/* Link to Home */}
                    <li>
                        <Link to="/" className="hover:text-blue-500 cursor-pointer text-white" onClick={toggleMenu}>Home</Link>
                    </li>

                    {/* Link to Contact Us */}
                    <li>
                        <Link to="/contact-us" className="hover:text-blue-500 cursor-pointer text-white" onClick={toggleMenu}>Contact Us</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
