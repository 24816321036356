import React from 'react';
import SectionHeader from './common/SectionHeader';

// Importing React Icons
import { FaFileAlt, FaMusic, FaWindows, FaApple, FaFont, FaImage, FaFacebook, FaLightbulb, FaCamera, FaVideo, FaTshirt, FaBrush, FaIcons } from 'react-icons/fa';
import { SiAdobe } from 'react-icons/si';

const assets = [
    { title: 'Trifold Brochure', icon: <FaFileAlt />, color: 'text-red-500' },
    { title: 'SOUND EFFECTS', icon: <FaMusic />, color: 'text-green-500' },
    { title: 'Software Windows', icon: <FaWindows />, color: 'text-blue-500' },
    { title: 'Software MacOS', icon: <FaApple />, color: 'text-gray-500' },
    { title: 'Photoshop Plugin Collection', icon: <SiAdobe />, color: 'text-indigo-500' },
    { title: 'Letterhead Template', icon: <FaFileAlt />, color: 'text-pink-500' },
    { title: 'FREE STOCK IMAGES', icon: <FaImage />, color: 'text-yellow-500' },
    { title: 'Fonts', icon: <FaFont />, color: 'text-purple-500' },
    { title: 'DVD Label Cover Template', icon: <FaFileAlt />, color: 'text-red-600' },
    { title: 'Creative Social Media Templates', icon: <FaFacebook />, color: 'text-blue-600' },
    { title: 'BONUS', icon: <FaLightbulb />, color: 'text-yellow-400' },
    { title: 'All Group Links - Facebook', icon: <FaFacebook />, color: 'text-blue-700' },
    { title: '18000 SVG', icon: <FaIcons />, color: 'text-green-400' },
    { title: '15000+ Photoshop Actions', icon: <SiAdobe />, color: 'text-indigo-600' },
    { title: '10000+ Icons', icon: <FaIcons />, color: 'text-purple-600' },
    { title: '8000+ Lightroom Presets', icon: <FaCamera />, color: 'text-blue-400' },
    { title: '6000+ Shapes Photoshop', icon: <FaBrush />, color: 'text-pink-400' },
    { title: '5000+ Motion Graphics Elements', icon: <FaVideo />, color: 'text-red-400' },
    { title: '5000+ Infographics', icon: <FaFileAlt />, color: 'text-yellow-600' },
    { title: '5000+ Gradient Buttons', icon: <FaFileAlt />, color: 'text-teal-500' },
    { title: '3000+ Logos', icon: <FaTshirt />, color: 'text-orange-500' },
    { title: '2500+ Premiere Pro Templates', icon: <FaVideo />, color: 'text-purple-400' },
    { title: '2500+ PNG & Clipart', icon: <FaFileAlt />, color: 'text-green-600' },
    { title: '2000+ Sound Effects', icon: <FaMusic />, color: 'text-indigo-400' },
    { title: '2000+ Premiere Pro Elements', icon: <FaVideo />, color: 'text-blue-500' },
    { title: '1500+ Websites Collection', icon: <FaFileAlt />, color: 'text-pink-500' },
    { title: '1200+ Wedding Album Templates', icon: <FaFileAlt />, color: 'text-yellow-700' },
    { title: '1000+ Graphics Library', icon: <FaFileAlt />, color: 'text-gray-600' },
    { title: '1000+ T-Shirt Design Templates', icon: <FaTshirt />, color: 'text-teal-600' },
    { title: '1000+ Photoshop Brushes', icon: <FaBrush />, color: 'text-purple-700' },
    { title: '1000+ Light Effects', icon: <FaLightbulb />, color: 'text-yellow-300' },
    { title: '500+ Social Media Templates', icon: <FaFacebook />, color: 'text-blue-700' },
    { title: '400+ Stock Video Footage', icon: <FaVideo />, color: 'text-red-700' },
    { title: '200+ Abstract Backgrounds', icon: <FaFileAlt />, color: 'text-blue-300' },
    { title: '200+ Wedding Text Effects', icon: <FaFileAlt />, color: 'text-green-700' },
    { title: '200+ Visiting Cards Templates', icon: <FaFileAlt />, color: 'text-teal-300' },
    { title: '200+ Colour Luts', icon: <FaLightbulb />, color: 'text-yellow-200' },
    { title: '100+ Mockups', icon: <FaFileAlt />, color: 'text-orange-600' },
    { title: '100+ Flyers', icon: <FaFileAlt />, color: 'text-red-300' },
    { title: '100+ Certificate Design Templates', icon: <FaFileAlt />, color: 'text-blue-600' },
    { title: '38,000 Adobe Lightroom Presets', icon: <FaCamera />, color: 'text-green-300' },
    { title: '30+ Digital Business Cards', icon: <FaFileAlt />, color: 'text-yellow-600' },
    { title: '20+ Free Video Courses', icon: <FaVideo />, color: 'text-red-600' },
    { title: '9 Million PLR Articles', icon: <FaFileAlt />, color: 'text-teal-400' },
    { title: '8,000 Final Cut Pro Assets', icon: <FaVideo />, color: 'text-orange-300' },
    { title: '5,000 Microsoft PowerPoint Templates', icon: <FaFileAlt />, color: 'text-blue-400' },
    { title: '5,000 Adobe Illustrator Templates', icon: <SiAdobe />, color: 'text-indigo-700' },
    { title: '5,000 Adobe After Effects Templates', icon: <SiAdobe />, color: 'text-purple-400' },
    { title: '5,000 CorelDraw Templates', icon: <FaFileAlt />, color: 'text-pink-400' },
    { title: '5,000 Adobe Premiere Pro Templates', icon: <FaVideo />, color: 'text-red-500' },
    { title: 'Over one million t-shirt design', icon: <FaTshirt />, color: 'text-orange-300' },
    { title: '150,000 Lr asset', icon: <FaCamera />, color: 'text-blue-600' }
];

const BundleOverview = () => {
    return (
        <section className="py-8"> {/* Reduced top-bottom padding for mobile */}
            <div className="container mx-auto px-2"> {/* Reduced padding */}
                <SectionHeader title="What's Included in the Ultimate Bundle" />
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 md:gap-4 lg:gap-8 mt-4 md:mt-8">
                    {assets.map((asset, index) => (
                        <div key={index} className="bg-white shadow-md p-2 md:p-4 rounded-lg text-center">
                            <div className={`mx-auto mb-1 w-12 h-12 md:w-16 md:h-16 text-2xl md:text-4xl flex justify-center items-center ${asset.color}`}>
                                {asset.icon}
                            </div>
                            <h3 className="text-sm md:text-lg font-semibold">{asset.title}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BundleOverview;
