// /src/services/adminService.js
const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/peteboonmang196`;

export const adminLogin = async (username, password) => {
    try {
        const response = await fetch(`${apiBaseUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            throw new Error('Invalid credentials');
        }

        return await response.json();
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const fetchPurchases = async () => {
    try {
        const response = await fetch(`${apiBaseUrl}/dashboard`, {
            method: 'GET',
            credentials: 'include', // Use session and JWT for validation
        });

        if (!response.ok) {
            throw new Error('Failed to fetch purchases');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching purchases:', error);
        throw error;
    }
};

export const adminLogout = async () => {
    try {
        const response = await fetch(`${apiBaseUrl}/logout`, {
            method: 'POST',
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Failed to log out');
        }

        return await response.json();
    } catch (error) {
        console.error('Error logging out:', error);
        throw error;
    }
};

export const deletePurchase = async (purchaseId) => {
    try {
        const response = await fetch(`${apiBaseUrl}/purchases/${purchaseId}`, {
            method: 'DELETE',
            credentials: 'include', // Allow cookies for session validation
        });

        if (!response.ok) {
            throw new Error('Failed to delete purchase');
        }

        return await response.json();
    } catch (error) {
        console.error('Error deleting purchase:', error);
        throw error;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { adminLogin, fetchPurchases, adminLogout, deletePurchase };
