import React, { useState } from 'react';

const faqData = [
    {
        question: 'Which licenses are included?',
        answer: 'The bundle comes with a standard commercial license which allows you to use the assets in personal and commercial projects.'
    },
    {
        question: 'How will I receive my products?',
        answer: 'Once you complete your purchase, you will receive an email with a download link to access all the products instantly.'
    },
    {
        question: 'Do I need particular skills?',
        answer: 'The bundle includes assets for all skill levels. Instructions and tutorials are provided for more complex resources.'
    },
    {
        question: 'Is it a one-time purchase?',
        answer: 'Yes, it is a one-time purchase with no recurring fees. You will receive lifetime access to the bundle.'
    },
    {
        question: 'Which methods of payment do you accept?',
        answer: 'We accept all major credit cards, PayPal, and other local payment methods depending on your region.'
    },
    {
        question: 'The payments are safe?',
        answer: 'Yes, all payments are processed securely using Stripe, one of the most trusted payment gateways in the world. Your payment information is encrypted and safely handled.'
    }
];

const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="bg-gray-900 text-white py-12">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h2>
                <div className="max-w-3xl mx-auto">
                    {faqData.map((faq, index) => (
                        <div
                            key={index}
                            className="border-b border-gray-700 py-4 cursor-pointer"
                            onClick={() => toggleFAQ(index)}
                        >
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg">{faq.question}</h3>
                                <span>{activeIndex === index ? '-' : '+'}</span>
                            </div>
                            <div
                                className={`overflow-hidden transition-all duration-500 ease-in-out ${activeIndex === index ? 'max-h-40' : 'max-h-0'
                                    }`}
                            >
                                <p className="mt-4 text-gray-400">{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQSection;
