// src/components/adminComponents/PurchaseList.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import adminService from '../../services/adminService';

const PurchaseList = () => {
    const [purchases, setPurchases] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPurchasesData = async () => {
            try {
                const data = await adminService.fetchPurchases();
                setPurchases(data);
            } catch (err) {
                setError('Failed to fetch purchases');
                navigate('/peteboonmang196'); // Redirect to login if access fails
            }
        };

        fetchPurchasesData();
    }, [navigate]);

    const handleDelete = async (purchaseId) => {
        try {
            await adminService.deletePurchase(purchaseId);
            // Update state to remove deleted purchase from the list
            setPurchases(purchases.filter((purchase) => purchase._id !== purchaseId));
        } catch (err) {
            setError('Failed to delete purchase');
            console.error('Error deleting purchase:', err);
        }
    };

    return (
        <div className="p-4 flex-1">
            <h2 className="text-xl mb-4">Purchase Emails</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {purchases.length > 0 ? (
                <ul className="bg-gray-800 p-4 rounded">
                    {purchases.map((purchase) => (
                        <li key={purchase._id} className="mb-2 border-b border-gray-700 p-2">
                            <p><strong>Email:</strong> {purchase.email}</p>
                            <p><strong>Transaction ID:</strong> {purchase.transactionId}</p>
                            <p><strong>Date:</strong> {new Date(purchase.createdAt).toLocaleDateString()}</p>
                            <button
                                onClick={() => handleDelete(purchase._id)}
                                className="p-1 bg-red-500 rounded hover:bg-red-600 mt-2"
                            >
                                Delete
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No purchases found</p>
            )}
        </div>
    );
};

export default PurchaseList;