// src/pages/AdminDashboard.jsx
import React, { useState } from 'react';
import Sidebar from '../components/adminComponents/Sidebar';
import PurchaseList from '../components/adminComponents/PurchaseList';
import LogoutButton from '../components/adminComponents/LogoutButton';

const AdminDashboard = () => {
    const [currentSection, setCurrentSection] = useState('purchases');

    return (
        <div className="flex min-h-screen bg-gray-900 text-white">
            <Sidebar currentSection={currentSection} setCurrentSection={setCurrentSection} />

            <div className="flex-1 p-8">
                <h1 className="text-3xl mb-8">Admin Dashboard</h1>

                <div className="mb-6">
                    <LogoutButton />
                </div>

                {/* Display content based on current section */}
                {currentSection === 'purchases' && <PurchaseList />}
            </div>
        </div>
    );
};

export default AdminDashboard;