// /src/pages/CancelPage.jsx
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { XCircleIcon } from '@heroicons/react/solid';

const CancelPage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-900 text-white">
            <Header />
            <main className="flex-grow flex items-center justify-center py-12 px-4">
                <div className="bg-gray-800 shadow-lg rounded-lg p-8 max-w-md text-center">
                    <XCircleIcon className="w-16 h-16 text-red-500 mx-auto mb-4" />
                    <h1 className="text-2xl font-semibold text-white mb-2">Payment Canceled</h1>
                    <p className="text-gray-300 mb-4">
                        Your payment process was canceled. You can try again whenever you're ready.
                    </p>
                    <button
                        onClick={() => window.location.href = '/'}
                        className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-150 ease-in-out"
                    >
                        Return to Home
                    </button>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default CancelPage;
