// src/components/ContactForm.jsx
import React, { useState } from 'react';
import contactService from '../services/contactService';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [feedback, setFeedback] = useState(''); // To show submission status
    const [fieldErrors, setFieldErrors] = useState({}); // To handle field-specific errors

    // Regular expressions for validation
    const nameRegex = /^[a-zA-Z\s]*$/; // Allows only letters and spaces
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Perform real-time validation and show errors if input is invalid
        if (name === 'name' && !nameRegex.test(value)) {
            setFieldErrors({
                ...fieldErrors,
                name: 'Name can only contain letters and spaces'
            });
        } else if (name === 'email' && !emailRegex.test(value)) {
            setFieldErrors({
                ...fieldErrors,
                email: 'Please enter a valid email address'
            });
        } else {
            // Clear specific field error if validation passes
            setFieldErrors({
                ...fieldErrors,
                [name]: ''
            });
        }

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Client-side validation before submission
        let validationErrors = {};

        if (!nameRegex.test(formData.name)) {
            validationErrors.name = 'Name can only contain letters and spaces';
        }
        if (!emailRegex.test(formData.email)) {
            validationErrors.email = 'Please enter a valid email address';
        }
        if (formData.message.length < 10) {
            validationErrors.message = 'Message must be at least 10 characters';
        }

        // If there are validation errors, set them and prevent submission
        if (Object.keys(validationErrors).length > 0) {
            setFieldErrors(validationErrors);
            return;
        }

        // Clear previous feedback
        setFeedback('');

        // Submit form data to the backend
        try {
            const response = await contactService.submitContactForm(formData);
            setFeedback(response.message); // Success or error message from the backend
            setFormData({ name: '', email: '', message: '' }); // Clear form on success
        } catch (error) {
            setFeedback('Failed to submit. Please try again later.');
        }
    };

    return (
        <section className="bg-gray-900 text-white py-12">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-semibold text-center mb-8">Contact Us</h2>
                <p className="text-center mb-4">Email us at: <a href="mailto:support@bundlecollection.com" className="text-blue-400">support@bundlecollection.com</a></p>
                <p className="text-center mb-8">or use the contact form below</p>
                <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-4 mb-2 bg-gray-800 text-white border-b border-gray-700 focus:outline-none focus:border-blue-500"
                    />
                    {fieldErrors.name && <p className="text-red-500 text-sm mb-4">{fieldErrors.name}</p>}

                    <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-4 mb-2 bg-gray-800 text-white border-b border-gray-700 focus:outline-none focus:border-blue-500"
                    />
                    {fieldErrors.email && <p className="text-red-500 text-sm mb-4">{fieldErrors.email}</p>}

                    <textarea
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full p-4 mb-2 bg-gray-800 text-white border-b border-gray-700 focus:outline-none focus:border-blue-500"
                        rows="5"
                    ></textarea>
                    {fieldErrors.message && <p className="text-red-500 text-sm mb-4">{fieldErrors.message}</p>}

                    <button type="submit" className="w-full bg-blue-500 text-white p-4 rounded hover:bg-blue-600">
                        Submit
                    </button>
                </form>
                {feedback && <p className="text-center mt-4">{feedback}</p>}
            </div>
        </section>
    );
};

export default ContactForm;
