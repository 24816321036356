// /src/services/contactService.js

const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/contact`; // Corrected base URL

/**
 * Function to submit the contact form by calling the backend API.
 * @param {object} formData - An object containing name, email, and message from the contact form.
 * @returns {Promise<object>} - The backend response with success or error message.
 */
export const submitContactForm = async (formData) => {
    try {
        const response = await fetch(`${apiBaseUrl}/submit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            throw new Error('Failed to submit contact form');
        }

        return await response.json(); // Backend response containing success or error message
    } catch (error) {
        console.error('Error submitting contact form:', error);
        throw error;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { submitContactForm };
