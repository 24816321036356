// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage'; // Your existing LandingPage component
import ContactUsPage from './pages/ContactUsPage'; // ContactUsPage component
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; // Import the Privacy Policy Page
import TermsOfServicePage from './pages/TermsOfServicePage'; // Import the Terms of Service Page
import SuccessPage from './pages/SuccessPage';
import CancelPage from './pages/CancelPage';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';

const App = () => {
  return (
    <Router>
      {/* Header is now included to navigate between pages */}
      {/* Define the routes */}
      <Routes>
        {/* Home route */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/cancel" element={<CancelPage />} />

        {/* Admin Routes */}
        <Route path="/peteboonmang196" element={<AdminLogin />} />
        <Route path="/peteboonmang196/dashboard/:uuid" element={<AdminDashboard />} />

      </Routes>
    </Router>
  );
};

export default App;
