// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-6 mt-8">
            <div className="container mx-auto text-center">
                <p className="text-sm">
                    © 2024 Digital Bundle Collection. All rights reserved.
                </p>
                <div className="mt-2">
                    <a href="/privacy-policy" className="text-blue-400 hover:underline mx-2">
                        Privacy Policy
                    </a>
                    <a href="/terms-of-service" className="text-blue-400 hover:underline mx-2">
                        Terms of Service
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
