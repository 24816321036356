// /src/pages/SuccessPage.jsx
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import checkoutService from '../services/checkoutService';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'; // Assuming you have Heroicons installed

const SuccessPage = () => {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('session_id');
    const [status, setStatus] = useState('loading');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchSuccessStatus = async () => {
            try {
                const response = await checkoutService.getSuccessStatus(sessionId);
                if (response.status === 'success') {
                    setStatus('success');
                    setMessage(response.message);
                } else {
                    setStatus('error');
                    setMessage('Payment verification failed. Please contact support.');
                }
            } catch (error) {
                console.error('Error fetching success status:', error);
                setStatus('error');
                setMessage('An error occurred while confirming the payment.');
            }
        };

        if (sessionId) {
            fetchSuccessStatus();
        }
    }, [sessionId]);

    return (
        <div className="flex flex-col min-h-screen bg-gray-900 text-white">
            <Header />
            <main className="flex-grow flex items-center justify-center py-12 px-4">
                <div className="bg-gray-800 shadow-lg rounded-lg p-8 max-w-md text-center">
                    {status === 'loading' ? (
                        <p className="text-gray-600">Loading...</p>
                    ) : status === 'success' ? (
                        <>
                            <CheckCircleIcon className="w-16 h-16 text-green-500 mx-auto mb-4" />
                            <h1 className="text-2xl font-semibold text-white mb-2">Thank You for Your Purchase!</h1>
                            <p className="text-gray-300 mb-2">
                                Payment successful! An email with the download link has been sent to your inbox.
                            </p>
                            <a
                                href="/" // Adjust this URL as needed
                                className="inline-block bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-150 ease-in-out"
                            >
                                Go to Dashboard
                            </a>
                        </>
                    ) : (
                        <>
                            <XCircleIcon className="w-16 h-16 text-red-500 mx-auto mb-4" />
                            <h1 className="text-2xl font-semibold text-gray-800 mb-2">Payment Verification Failed</h1>
                            <p className="text-gray-600 mb-4">
                                {message || 'Unfortunately, we could not verify your payment. Please contact support for assistance.'}
                            </p>
                            <a
                                href="/contact" // Adjust this URL as needed
                                className="inline-block bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-150 ease-in-out"
                            >
                                Contact Support
                            </a>
                        </>
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default SuccessPage;
