// src/pages/LandingPage.jsx
import React from 'react';
import HeroSection from '../components/HeroSection';
import BundleOverview from '../components/BundleOverview';
import BuyerRights from '../components/BuyerRights';
import BuyerBenefits from '../components/BuyerBenefits';
import CategorySection from '../components/CategorySection';
import FAQSection from '../components/FAQSection';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LandingPage = () => {
    return (
        <div>
            <Header />
            <HeroSection />
            <BundleOverview />
            <BuyerRights />
            <BuyerBenefits />
            <CategorySection /> {/* Integrate CategorySection here */}
            <FAQSection />
            <Footer />
        </div>
    );
};

export default LandingPage;
