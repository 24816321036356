import React from 'react';
import SectionHeader from './common/SectionHeader';
import { createCheckoutSession } from '../services/checkoutService'; // Import the service

// Correct import paths for logos
import photoshopLogo from '../assets/photoshop.png';
import lightroomLogo from '../assets/photoshop-lightroom.png';
import illustratorLogo from '../assets/illustrator.png';
import afterEffectsLogo from '../assets/after-effects.png';
import premiereProLogo from '../assets/premiere-pro.png';
import xdLogo from '../assets/xd.png';
import finalcutLogo from '../assets/finalcut.png';
import canvaLogo from '../assets/canva.png';

import photoshop_15000 from '../assets/categorySection/photoshop_15000.png';
import icon_10000 from '../assets/categorySection/icon_10000.png';
import motion_5000 from '../assets/categorySection/motion_5000.png';
import finalcut_8000 from '../assets/categorySection/finalcut_8000.png';
import photoshop_brush from '../assets/categorySection/photoshop_brush.png';
import tshirt_million from '../assets/categorySection/tshirt_million.png';
import lightroom_150000 from '../assets/categorySection/lightroom_150000.png';
import social_media_500 from '../assets/categorySection/social_media_500.png';
import premiere_2500 from '../assets/categorySection/premiere_2500.png';
import wedding_1200 from '../assets/categorySection/wedding_1200.png';
import ai_5000 from '../assets/categorySection/ai_5000.png';
import plr_9million from '../assets/categorySection/plr_9million.png';

// Emphasize assets using placeholder image for now
const assets = [
    {
        title: '15000+ Photoshop Actions',
        description: 'A vast collection of Photoshop actions designed to speed up your editing process and enhance your creativity. Perfect for both beginners and professionals, these actions cover effects, filters, and adjustments that elevate your design projects effortlessly.',
        image: photoshop_15000
    },
    {
        title: '10,000+ Icons',
        description: 'This collection of icons covers various themes and styles, perfect for web and app developers, designers, and marketers. Each icon is customizable and suitable for a wide range of applications.',
        image: icon_10000
    },
    {
        title: '5,000+ Motion Graphics Elements',
        description: 'Boost your video projects with an impressive array of motion graphic elements. These customizable templates are ideal for video editors, content creators, and animators.',
        image: motion_5000
    },
    {
        title: '8,000 Final Cut Pro Assets',
        description: 'A comprehensive set of assets for Final Cut Pro, including transitions, titles, effects, and more. These assets streamline your editing process and help create professional-quality videos.',
        image: finalcut_8000
    },
    {
        title: '1,000+ Photoshop Brushes',
        description: 'A versatile collection of Photoshop brushes that allow you to add unique textures, effects, and artistic flair to your designs. Perfect for illustrators, digital painters, and graphic designers.',
        image: photoshop_brush
    },
    {
        title: 'Over One Million T-Shirt Designs Bundle 3.0',
        description: 'An extensive library of T-shirt designs with over one million assets. This bundle covers multiple styles and themes, providing endless possibilities for apparel designers.',
        image: tshirt_million
    },
    {
        title: '150,000+ Lightroom Presets Bundle 3.0',
        description: 'Take your photo editing to the next level with over 150,000 Lightroom presets. This bundle provides a variety of presets for all types of photography styles.',
        image: lightroom_150000
    },
    {
        title: '500+ Social Media Templates',
        description: 'Pre-designed social media templates that cover various platforms like Instagram, Facebook, and Twitter. Create consistent, visually appealing posts quickly.',
        image: social_media_500
    },
    {
        title: '2,500+ Premiere Pro Templates',
        description: 'This collection of Premiere Pro templates includes transitions, intros, and effects, making video editing faster and more efficient.',
        image: premiere_2500
    },
    {
        title: '1,200+ Wedding Album Templates',
        description: 'A beautiful collection of wedding album templates, fully customizable for photographers or individuals creating keepsakes. Elegant, modern, and easy to personalize.',
        image: wedding_1200
    },
    {
        title: '5,000 Adobe Illustrator Templates',
        description: 'A variety of Adobe Illustrator templates ranging from business cards, brochures, to flyers. Ideal for designers needing professional-quality assets for quick turnaround projects.',
        image: ai_5000
    },
    {
        title: '9 Million PLR Articles',
        description: 'A huge repository of Private Label Rights (PLR) articles covering multiple topics. These can be used for content marketing, blog posts, newsletters, and more.',
        image: plr_9million
    }
];

const logos = [
    photoshopLogo,
    lightroomLogo,
    illustratorLogo,
    afterEffectsLogo,
    premiereProLogo,
    xdLogo,
    finalcutLogo,
    canvaLogo
];

const CategorySection = () => {
    const handleBuyNow = async () => {
        try {
            const checkoutUrl = await createCheckoutSession();
            window.location.href = checkoutUrl;
        } catch (error) {
            alert('Failed to initiate checkout. Please try again.');
        }
    };

    return (
        <section className="py-16 bg-gray-200 text-gray-900">
            <div className="container mx-auto px-4">
                <SectionHeader title="Imagine Having..." customClass="text-black" />

                {/* List of Main Assets */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                    {assets.map((asset, index) => (
                        <div key={index} className="flex bg-white shadow-md p-6 rounded-lg">
                            <img
                                src={asset.image}
                                alt={asset.title}
                                className="w-32 h-32 object-cover rounded-md mr-6"
                            />
                            <div>
                                <h3 className="text-xl font-semibold mb-2">{asset.title}</h3>
                                <p className="text-gray-600">{asset.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Logos Section */}
                <div className="mt-12 text-center">
                    <h2 className="text-2xl font-bold text-gray-900 mb-4 lg:text-4xl">And Much More!</h2>
                    <p className="text-red-600 mb-2 lg:text-xl">Increase your earnings and efficiency with time-saving tools and assets.</p>
                    <p className="text-gray-800 mb-4 lg:text-xl">Join over 20,000 creators worldwide who rely on us to create stunning content, videos, and graphics every day.</p>

                    {/* Display Logos */}
                    <div className="flex flex-wrap justify-center items-center mt-6">
                        {logos.map((logo, index) => (
                            <img
                                key={index}
                                src={logo}
                                alt="Logo"
                                className="w-12 h-12 mx-2 my-2 md:w-16 md:h-16 lg:w-20 lg:h-20"
                            />
                        ))}
                    </div>
                </div>

                {/* Buy Now Banner Section */}
                <div className="mt-12 bg-red-600 text-white text-center py-6">
                    <p className="text-lg font-semibold mb-4">Don't delay, take advantage of this offer now!</p>
                    <button
                        onClick={handleBuyNow}
                        className="bg-white text-red-600 font-semibold px-6 py-3 rounded-full text-lg hover:bg-gray-100 hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out animate-bounce"
                    >
                        Buy Now
                    </button>
                </div>
            </div>
        </section>
    );
};

export default CategorySection;