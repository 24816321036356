import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PrivacyPolicyContent from '../components/PrivacyPolicyContent';

const PrivacyPolicyPage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-900">
            <Header />
            <main>
                <PrivacyPolicyContent />
            </main>
            <Footer />
        </div>
    );
};

export default PrivacyPolicyPage;