// src/components/PrivacyPolicyContent.jsx
import React from 'react';

const PrivacyPolicyContent = () => {
    return (
        <section className="bg-gray-900 text-white py-12">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-semibold text-center mb-8">Privacy Policy for Bundle Collections</h2>
                <div className="text-left text-gray-300 leading-relaxed">
                    <h3 className="text-xl font-semibold mt-6">1. Introduction</h3>
                    <p>
                        Welcome to Bundle Collections. We highly value your privacy and are committed to ensuring the protection of your personal information. This Privacy Policy provides a detailed overview of how we collect, use, disclose, and safeguard your data when you visit our website or purchase our bundle of graphic elements. By using our site, you consent to the practices outlined in this policy.
                    </p>
                    <h3 className="text-xl font-semibold mt-6">2. Information We Collect</h3>
                    <p>
                        We collect different types of information to provide, enhance, and personalize our services:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li><strong>Personal Information</strong>: When you make a purchase or create an account, we collect personal information such as your name, email address, billing address, and payment information.</li>
                        <li><strong>Usage Data</strong>: We gather data on how you access and interact with our website, including your IP address, browser type, pages visited, time spent on pages, and other diagnostic data.</li>
                        <li><strong>Cookies and Tracking Technologies</strong>: We utilize cookies, beacons, and similar technologies to monitor activity on our website and store information to improve your browsing experience.</li>
                    </ul>
                    <h3 className="text-xl font-semibold mt-6">3. How We Use Your Information</h3>
                    <p>
                        We use the information we collect for several purposes, including:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li>Processing and fulfilling your orders</li>
                        <li>Providing customer support</li>
                        <li>Enhancing and improving our website, products, and services</li>
                        <li>Sending transactional emails related to your order (e.g., order confirmations, download links)</li>
                        <li>Sending promotional emails if you have opted in to receive marketing communications</li>
                        <li>Monitoring and analyzing website usage to optimize user experience</li>
                    </ul>
                    <h3 className="text-xl font-semibold mt-6">4. Sharing Your Information</h3>
                    <p>
                        We do not sell or trade your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or providing services to you. All such parties operate under strict confidentiality agreements. These may include:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li><strong>Payment Processors</strong>: To facilitate secure payment transactions</li>
                        <li><strong>Email Service Providers</strong>: To send transactional and promotional communications</li>
                        <li><strong>Analytics Providers</strong>: To analyze how users interact with our site for service improvement</li>
                    </ul>
                    <h3 className="text-xl font-semibold mt-6">5. Data Retention</h3>
                    <p>
                        We retain your personal information only as long as it is necessary to fulfill the purposes described in this Privacy Policy, unless a longer retention period is required or permitted by applicable law.
                    </p>
                    <h3 className="text-xl font-semibold mt-6">6. Your Rights and Choices</h3>
                    <p>
                        Depending on your jurisdiction, you may have certain rights with respect to your personal information:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li><strong>Access</strong>: You have the right to access the information we hold about you.</li>
                        <li><strong>Correction</strong>: You may request corrections to any inaccurate or incomplete information.</li>
                        <li><strong>Deletion</strong>: You may request the deletion of your personal information, subject to legal obligations.</li>
                        <li><strong>Opt-Out</strong>: You may opt out of receiving promotional communications at any time by clicking the "unsubscribe" link included in our emails.</li>
                    </ul>
                    <h3 className="text-xl font-semibold mt-6">7. Security of Your Information</h3>
                    <p>
                        We implement industry-standard security measures to safeguard your information, including secure servers, encryption, and restricted access protocols. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your data.
                    </p>
                    <h3 className="text-xl font-semibold mt-6">8. Cookies and Tracking Technologies</h3>
                    <p>
                        We use cookies to personalize and enhance your browsing experience by remembering your preferences. You may control or disable cookies through your browser settings, although some features of our site may be impacted by doing so.
                    </p>
                    <h3 className="text-xl font-semibold mt-6">9. Changes to This Privacy Policy</h3>
                    <p>
                        We may update this Privacy Policy periodically to reflect changes in our practices, technology, or legal requirements. Any updates will be posted on this page, and, where appropriate, we will notify you via email or through a website notification.
                    </p>
                    <h3 className="text-xl font-semibold mt-6">10. Contact Us</h3>
                    <p>
                        If you have any questions or concerns regarding this Privacy Policy or how we handle your information, please contact us at:
                    </p>
                    <p>
                        Email: <a href="mailto:support@bundlecollections.com" className="text-blue-400">support@bundlecollections.com</a>
                    </p>
                    <p>
                        Thank you for choosing Bundle Collections. Your privacy is important to us, and we are dedicated to safeguarding it to the best of our ability.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicyContent;
